import Vue from 'vue'
import VueRouter from 'vue-router'
import { beforeEachRouterEntry } from './before-each-router'

Vue.use(VueRouter)

const showDeveloperAPI = false

let routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/LoginPage/LoginPage.vue'),
  },
  {
    path: '/engagement',
    name: 'engagement',
    component: () => import('@/pages/Definitions/EngagementPage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import('@/pages/Definitions/PerformancePage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/performance-service',
    name: 'performance-service',
    component: () => import('@/pages/Definitions/PerformanceServicePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/pages/Definitions/NotificationPage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/management',
    name: 'management',
    component: () => import('@/pages/Definitions/ManagementPage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/management-service',
    name: 'management-service',
    component: () => import('@/pages/Definitions/ManagementServicePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/growth',
    name: 'growth',
    component: () => import('@/pages/Definitions/GrowthPage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/growth-service',
    name: 'growth-service',
    component: () => import('@/pages/Definitions/GrowthServicePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/api',
    name: 'api',
    component: () => import('@/pages/Definitions/APIPage.vue'),
    meta: {
      requiresAuth: true,
      showDeveloperAPI,
    },
  },
  {
    path: '/api-data',
    name: 'apiData',
    component: () => import('@/pages/Definitions/APIDataPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/api-data-import',
    name: 'apiDataImport',
    component: () => import('@/pages/Definitions/APIDataImportPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '*',
    redirect: '/login',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(beforeEachRouterEntry)

export default router
