import { projectAuth } from '@/firebase/config'

export const beforeEachRouterEntry = async (to, from, next) => {
  const currentUser = projectAuth.currentUser
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  const { showDeveloperAPI } = to.meta

  if (requiresAuth && !currentUser) {
    next('login')
  } else if (!requiresAuth && currentUser) {
    next('/')
  } else if (showDeveloperAPI === false) {
    next('/dashboard')
  } else {
    next()
  }
}

export default { beforeEachRouterEntry }
